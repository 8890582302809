import type { Maybe } from "@vue-storefront/unified-data-model";

import type { InferSdk } from "../sdk.config";

export const cacheKey = "cache:categories";

export interface UseCategoryTreeState {
  data: Maybe<InferSdk<"getCategoryTree">>;
  loading: boolean;
}

const useCategoryTree = () => {
  const state = useState<UseCategoryTreeState>(`useCategoryTree`, () => ({
    data: null,
    loading: false,
  }));

  const config = useRuntimeConfig();

  const filterVisibleCategories = (list: any[] | null): Maybe<InferSdk<"getCategoryTree">> => {
    if (list && list.length > 0) {
      return list
        .map((item) => (item.children ? { ...item, children: filterVisibleCategories(item.children) } : item))
        .filter((item) => item.is_visible)
        .map((item) => {
          if (item.name !== "Kitchens") {
            return item;
          }
          return {
            ...item,
            children: [
              { name: "Contemporary Kitchens", url: "/kitchens/contemporary" },
              { name: "Classic Kitchens", url: "/kitchens/classic" },
              { name: "Worktops & Splashbacks", url: "/kitchens/worktops-splashbacks" },
              { name: "Finishing Touches", url: "/kitchens/finishing-touches" },
              { name: "Appliances", url: "/kitchens/appliances" },
              { name: "Book a free kitchen design", url: "/kitchens/kitchens-enquiry" },
            ],
          };
        });
    }
    return [];
  };

  const getCategoryTreeItems = async () => {
    const { data, error } = await useTimedAsyncData("categoryTreeBC", () => useSdk().bcAPI.getCategoryTree());

    useHandleError(error.value);
    state.value.data = filterVisibleCategories(data.value);

    return data.value;
  };

  const useCache = async () => {
    const { data: cacheData, error: cacheError } = await useTimedAsyncData("categoryTreeRedis", () =>
      useSdk().redis.getOrSetCache({
        key: cacheKey,
        data: async () => useSdk().bcAPI.getCategoryTree(),
        timeToLive: 60 * 60 * 12,
      }),
    );

    if (cacheError.value) {
      await getCategoryTreeItems();
      return;
    }

    useHandleError(cacheError.value);

    // @ts-ignore
    state.value.data = filterVisibleCategories(cacheData.value);

    return cacheData.value;
  };

  const fetchCategoryTree = async () => {
    state.value.loading = true;
    try {
      config.public.CACHE_ENABLED === "true" ? await useCache() : await getCategoryTreeItems();
    } finally {
      state.value.loading = false;
    }
  };

  return {
    fetchCategoryTree,
    ...toRefs(state.value),
  };
};

export default useCategoryTree;
